.header {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: -24px;
  z-index: 100;
  padding: 24px 26px;
  margin: -24px -26px 0 -26px;
  background: var(--gray-3);

  & > :global(.ant-page-header) {
    background: var(--gray-1);
    border-radius: 4px;
  }
}

.wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  :global {
    .ant-page-header-heading-left,
    .ant-page-header-heading-sub-title {
      flex-grow: 1;
    }
  }
}

.content {
  flex-grow: 1;
}
