.wrapper {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 100%;
}

.chart {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-end;
  flex: 1 1 auto;
  height: 100%;
  padding: 12px 6px;
  min-width: 100px;
  max-width: 200px;
  min-height: 100px;
}

.title {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 19px;
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  pointer-events: none;
}

.legend {
  display: flex;
  flex: 0 1 180px;
  flex-direction: column;
  justify-content: center;
  padding: 0 6px 0 16px;
  gap: 16px;
}
