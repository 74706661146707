.warning {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 8px;
  background: var(--gray-10);
  text-align: center;
  color: var(--gray-1);
}
.browserCookies {
  padding: 0;
  color: var(--gray-10);

  &:focus,
  &:hover {
    color: var(--gray-10);
  }

  span {
    text-decoration: underline;
  }
}

.notAcceptedCookies {
  padding: 0;
  color: var(--gray-1);

  &:focus,
  &:hover {
    color: var(--gray-1);
  }

  span {
    text-decoration: underline;
  }
}

.acceptButton {
  margin-left: 8px;
}

.modal {
  :global {
    .ant-modal-header,
    .ant-modal-body {
      text-align: center;
    }
  }
}
