.wrapper {
  position: relative;
  height: 100%;
  border-radius: 4px;
  overflow: hidden;
}

.map {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
