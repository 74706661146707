.wrapper {
  display: flex;
  flex-direction: column;
  background-color: var(--gray-1);
  padding: 8px;
  border-radius: 4px;
  flex: 1;

  &:not(.preview) {
    height: 100%;
  }
}

.header {
  display: flex;
  gap: 12px;
  height: 44px;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: var(--gray-3);
  margin-bottom: 8px;
  border-radius: 2px;
}

.content {
  flex: 1;
  display: flex;
  align-items: center;

  & > * {
    flex: 1;
  }

  :global {
    .ant-table-wrapper {
      height: 100%;
    }

    .ant-table-tbody > tr > td {
      border: none;
      height: 34px;
    }

    .ant-table-small .ant-table-thead > tr > th {
      background: transparent;
      border: none;
      color: var(--gray-7);
      font-size: 12px;
      line-height: 16px;
      padding: 0 4px 4px;
      height: 36px;
      font-weight: 400;

      &::before {
        display: none;
      }
    }

    .ant-table.ant-table-small .ant-table-tbody .ant-table-cell {
      padding: 4px 6px;
    }

    .ant-tag {
      padding: 1px 6px;
    }
  }
}

.preview {
  border: 1px solid var(--gray-5);
  margin-bottom: 16px;

  .content {
    height: 214px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  :global {
    .ant-form-item {
      margin: 0;
    }
  }
}
