.button {
  color: var(--gray-10);
  width: 100%;
  padding: 4px 0;
  text-align: left;

  :global {
    .anticon {
      color: var(--gray-6);
    }
  }

  &:hover,
  &:focus {
    color: var(--gray-10);
  }

  &:global(.ant-dropdown-open) {
    color: var(--gray-6);
  }
}

.menuWrapper {
  :global {
    .ant-dropdown-menu-item-selected {
      color: var(--gray-10);
      font-weight: 600;
    }
  }
}
