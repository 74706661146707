.form {
  margin-top: 32px;
}

.hCenter {
  display: flex;
  justify-content: center;
}

.actions {
  margin-top: 32px;
}

.or {
  margin-bottom: 16px;
  text-align: center;
}
