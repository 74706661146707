#root {
  height: 100%;
}

:root {
  /* grey colors */
  --gray-1: #fff;
  --gray-2: #fafafa;
  --gray-3: #f5f5f5;
  --gray-4: #e8e8e8;
  --gray-5: #d9d9d9;
  --gray-6: #bfbfbf;
  --gray-7: #8c8c8c;
  --gray-8: #595959;
  --gray-9: #262626;
  --gray-10: #000;

  /* blue colors */
  --blue-1: #e1efff;
  --blue-2: #bbdaff;
  --blue-3: #8ec2ff;
  --blue-4: #69a5ff;
  --blue-5: #408af9;
  --blue-6: #196ae2;
  --blue-7: #135bc8;
  --blue-8: #0043a8;
  --blue-9: #00327d;
  --blue-10: #00255c;

  /* red colors */
  --red-1: #fff1f0;
  --red-2: #ffccc7;
  --red-3: #ffa39e;
  --red-4: #ff7875;
  --red-5: #ff4d4f;
  --red-6: #f5222d;
  --red-7: #cf1322;
  --red-8: #a8071a;
  --red-9: #820014;
  --red-10: #5c0011;

  /* green colors */
  --green-1: #f6ffed;
  --green-2: #d9f7be;
  --green-3: #b7eb8f;
  --green-4: #95de64;
  --green-5: #73d13d;
  --green-6: #52c41a;
  --green-7: #389e0d;
  --green-8: #237804;
  --green-9: #135200;
  --green-10: #092b00;

  /* orange colors */
  --orange-1: #fff7e6;
  --orange-2: #ffe7ba;
  --orange-3: #ffd591;
  --orange-4: #ffc069;
  --orange-5: #ffa940;
  --orange-6: #fa8c16;
  --orange-7: #d46b08;
  --orange-8: #ad4e00;
  --orange-9: #873800;
  --orange-10: #612500;
}

.ant-form-item {
  transition: none; /* Disabled, because transition of "antd" causes form jumps */
}

.ant-form-item-label {
  font-weight: 600;
}

.ant-descriptions-header {
  padding: 16px;
  border-bottom: 1px solid var(--gray-3);
}

.ant-descriptions-view {
  padding: 0 16px;
}

.ant-descriptions-item-label {
  font-weight: 600;

  &::after {
    display: none; /* Colon hide */
  }
}

.ant-descriptions-item-content {
  color: var(--gray-8);
  font-weight: 400;
  font-size: 14px;
}

.ant-typography {
  h1&,
  h2&,
  h3&,
  h4&,
  h5&,
  h1,
  h2,
  h3,
  h4,
  h5 {
    margin-bottom: 0;
  }
}

.ant-input-number {
  width: 100%;
}

.ant-select-dropdown {
  z-index: 999;
}
