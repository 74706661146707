.uploadContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 24px;
}

.upload {
  display: flex;
  flex-direction: column;
  align-items: center;
}
