.wrapper {
  width: 100%;
  height: 100%;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: var(--gray-1);
}

.content {
  position: relative;
  text-align: center;
  max-width: 320px;

  .code,
  .title,
  .message {
    position: relative;
  }
}

.code {
  font-size: 140px;
  line-height: 167px;
  font-weight: 700;
  margin-bottom: 15px;
  color: var(--blue-6);
}

.title {
  font-size: 38px;
  line-height: 46px;
  font-weight: 600;
  margin-bottom: 30px;
  color: var(--blue-10);
}

.message {
  font-size: 24px;
  line-height: 32px;
  color: var(--gray-8);
}
