.wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.container {
  width: 100%;
  max-width: 600px;
  border: 1px solid var(--gray-5);
  border-radius: 4px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: var(--gray-3);
  padding: 16px 24px;
  border-radius: 4px;

  & > img {
    width: 130px;
  }
}

.subtitle {
  color: var(--gray-1);
  font-weight: 600;
}

.content {
  padding: 32px 24px;
  background: var(--gray-1);
  border-bottom: solid 1px var(--gray-4);
}

.footer {
  display: flex;
  justify-content: center;
  padding: 24px;
  gap: 8px;
  background: var(--gray-1);
  border-radius: 0 0 4px 4px;
}

.adminWrapper {
  background: var(--gray-8);

  .container {
    border: 1px solid var(--gray-8);
  }

  .header {
    position: relative;
    background: var(--gray-10);
    padding-right: 96px;
  }

  :global {
    .ant-select {
      position: absolute;
      z-index: 1;
      right: 24px;
    }
  }
}
