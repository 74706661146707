.scrollbarContainer {
  position: relative;
}

.trackVertical {
  position: absolute;
  top: 2px;
  right: 0;
  bottom: 2px;
  background-color: var(--gray-3);
}
