.wrapper {
  & > span {
    display: inline-block;
    line-height: 26px;
  }

  :global(.ant-tag) {
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    padding: 2px 8px;
    margin: 0;
  }
}
