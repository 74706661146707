.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.left,
.right {
  position: absolute;
}

.left {
  left: -104px;
  top: calc(50% - 46px);
}

.right {
  right: -121px;
  top: calc(50% - 129px);
}

.coneSmall {
  position: absolute;
  left: -36px;
  top: 163px;
}

.cone {
  position: absolute;
  left: -23px;
  top: 205px;
}
