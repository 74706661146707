.tag {
  max-width: 100%;

  span {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
  }
}
