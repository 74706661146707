.wrapper {
  :global {
    .ant-space-item {
      flex-grow: 1;

      &:first-child {
        flex-grow: 0;
      }
    }
  }

  :global(.ant-select-selection-item) {
    .name,
    .code {
      display: none;
    }
  }

  :global(.ant-select-dropdown) {
    max-width: 300px;

    .dropdown {
      display: flex;
      align-items: center;
      text-align: left;

      .flag,
      .code {
        flex: 0 0 auto;
      }

      .name {
        flex: 1 1 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 4px;
      }
    }
  }
}

.dropdown {
  overflow: hidden;
  text-overflow: ellipsis;
}
