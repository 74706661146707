.menu {
  color: var(--gray-8);
}

:global {
  .ant-menu-inline-collapsed-tooltip {
    a {
      color: var(--gray-1);
    }
  }
}
