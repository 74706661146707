.wrapper {
  background: var(--gray-1);

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  :global {
    .ant-descriptions:not(:last-child) {
      margin-bottom: 24px;
    }
    .ant-descriptions-view {
      padding: 0;
    }
    th.ant-descriptions-item {
      padding-bottom: 8px;
    }
    td.ant-descriptions-item {
      padding-bottom: 24px;
    }
    .ant-descriptions-item:not(:last-child) {
      padding-right: 24px;
    }
    .ant-descriptions-row:last-child {
      td {
        padding-bottom: 0;
      }
    }
    .ant-descriptions-item-content {
      flex-wrap: wrap;

      .ant-tag {
        margin-bottom: 8px;
        white-space: normal;
      }
    }
  }
}

.header {
  display: inline-flex;
  align-items: center;
}

.title {
  &:global(.ant-typography) {
    margin-bottom: 0;
  }
}

.subTitle {
  margin-left: 12px;
}

.collapse {
  :global {
    .ant-collapse-header {
      padding: 16px !important;
      border-bottom: solid 1px var(--gray-3);
    }
  }
}

.card {
  :global {
    .ant-card-head {
      padding: 0 16px;
      border-bottom: solid 1px var(--gray-3);
    }
  }
}

.table {
  border-radius: 4px;

  :global {
    .ant-table-wrapper {
      padding: 0;
      border-radius: 0;
    }

    .ant-card-body {
      padding: 8px;
    }

    .ant-card-head {
      padding: 8px 8px 0 8px;
      border-bottom: none;
      min-height: 0;
    }

    .ant-card-extra {
      padding: 0;
    }
  }
}
