.wrapper {
  display: flex;
  margin-top: 2px;

  & > :global(.ant-form-item) {
    margin-bottom: 0;
    flex-wrap: nowrap;

    label {
      font-weight: 600;
      color: var(--gray-7);
    }
  }

  :global {
    .ant-form-item-control {
      min-width: 80px;
      max-width: 200px;
    }
  }
}
