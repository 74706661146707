.wrapper {
  padding: 8px;
  background-color: var(--gray-1);
  border-radius: 4px;

  :global {
    .ant-table tr > th {
      font-weight: 600;
    }

    .ant-table tr > td {
      color: var(--gray-8);
    }

    .ant-table tr > th,
    .ant-table tr > td {
      min-width: 70px;

      /* for long words don't break table layout: */
      max-width: 200px;
    }

    .ant-table-pagination.ant-pagination {
      margin-right: 16px;
    }
  }
}
