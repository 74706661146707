.wrapper {
  gap: 24px;
  margin: 0 -8px 24px;

  :global {
    .slick-track {
      display: flex;
    }

    .slick-slide {
      padding: 0 8px;
      height: auto;

      & > div {
        height: 100%;
      }
    }

    .slick-dots {
      bottom: -32px;

      li {
        margin: 0;
        width: auto;
        height: auto;

        button {
          position: relative;
          width: 24px;
          height: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: transparent;

          &::before {
            content: "";
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: var(--gray-8);
          }
        }

        &.slick-active {
          width: auto;
          button {
            background-color: transparent;
          }
        }
      }
    }

    .slick-arrow {
      color: unset;
      font-size: unset;
      background: var(--gray-3);
      height: 100%;
      margin: 0;
      width: 26px;
      top: 0;
      z-index: 1;

      &::before {
        content: "";
        display: none;
      }

      &.slick-disabled {
        cursor: auto;
        background: var(--gray-3);
        .anticon {
          opacity: 0.3;
        }
      }

      &:hover,
      &:focus {
        color: unset;
      }

      &:hover:not(.slick-disabled) {
        background: var(--gray-4);
      }
    }

    .slick-prev {
      left: -18px;
    }

    .slick-next {
      right: -18px;
    }
  }
}

.widgetItem {
  height: 100%;
}
