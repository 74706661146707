.item {
  display: flex;
  gap: 8px;
  align-items: center;
}

.label {
  position: relative;
  flex: 1 1 auto;
  min-width: 0;

  span {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.mark {
  flex: 0 0 auto;
  width: 12px;
  height: 12px;
  border-radius: 2px;
}

.count {
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
}
