.block {
  margin-top: 32px;
}

.textCenter {
  text-align: center;
}

.hCenter {
  display: flex;
  justify-content: center;
}

.primaryText {
  color: var(--blue-6);
}

.codeInputContainer {
  margin-bottom: 16px;
  :global {
    .pincode-input-container {
      display: flex;
      justify-content: center;
    }
    .pincode-input-text {
      width: 36px !important;
      height: 32px !important;
      margin: 0 4px !important;
      padding: 0;
      border: 1px solid var(--gray-5) !important;
      border-radius: 4px;
      font-family: inherit !important;
      outline: none;
      user-select: text;
      &:focus {
        border: 1px solid var(--blue-5) !important;
      }
    }
  }
}

.reset {
  color: var(--blue-6);
  cursor: pointer;
}
