.contentItem {
  border-radius: 4px;

  :global {
    .ant-card-body {
      padding: 0 8px 24px 8px;
    }

    .ant-table-wrapper {
      padding: 0;
    }

    .ant-tabs-nav {
      margin: 0;
    }

    .ant-tabs-nav-wrap {
      margin-top: 8px;
      margin-bottom: -1px;
    }

    .ant-table-container {
      border-top-left-radius: 0;

      table,
      table > thead > tr:first-child th:first-child {
        border-top-left-radius: 0;
      }
    }

    .ant-tabs-tab .anticon {
      margin-right: 6px;
    }
  }
}
