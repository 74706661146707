.form {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.formContent {
  flex: 1 1;
}

.footer {
  display: flex;
  justify-content: flex-end;
}
