.wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}

.left,
.right {
  position: absolute;
}

.left {
  left: -100px;
  top: calc(50% - 88px);
}

.right {
  right: -64px;
  top: calc(50% - 120px);
}

.lock {
  position: absolute;
  left: 70px;
  top: 44px;
}
.gear {
  position: absolute;
  top: -40px;
  left: 51px;
}
