.wrapper {
  border-radius: 4px;
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 6px;
}

.date {
  margin-bottom: 10px;
  margin-top: 7px;
  margin-left: 2px;
  font-size: 12px;
}
