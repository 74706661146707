.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.title {
  width: 100%;
  flex: 0 0 44px;
  line-height: 19px;
  font-weight: 600;
  font-size: 14px;
}

.chart {
  display: flex;
  flex: 1 1 auto;
  max-width: 500px;
}
