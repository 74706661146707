.countryCodeSelect {
  width: 80px;
  transition-delay: 0.3s;

  :global(.anticon-down) {
    color: var(--blue-6);
  }

  :global(.ant-select-selection-item) {
    cursor: pointer;
    text-align: right;

    .name,
    .code {
      display: none;
    }
  }

  &:hover,
  &:global(.ant-select-focused) {
    width: 300px;
    transition-delay: unset;
  }

  &:not(:global(.ant-select-focused)) {
    :global {
      .ant-select-selector {
        border-color: transparent;
        background: none;

        &:hover {
          border-color: transparent;
        }
      }
    }
  }

  :global(.ant-select-dropdown) {
    max-width: 100%;

    .dropdown {
      display: flex;
      align-items: center;
      text-align: left;

      .flag,
      .code {
        flex: 0 0 auto;
      }

      .name {
        flex: 1 1 auto;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0 4px;
      }
    }
  }
}

.dropdown {
  overflow: hidden;
  text-overflow: ellipsis;
}
